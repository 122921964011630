import { Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CalendarDaysIcon,
  MapPinIcon,
  PhoneIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Fragment } from "react";
import SmallLogo from "./assets/Logo/Small.svg";
import Hero from "./assets/graphic.svg";

const navigation = [
  { name: "How it works", href: "#howitworks" },
  { name: "Services", href: "#services" },
  { name: "Contact us", href: "#contact" },
];

const works = [
  {
    name: "1. Schedule an appointment",
    description: "Choose a service and a date and time that works for you.",
    icon: CalendarDaysIcon,
  },
  {
    name: "2. Find a technician",
    description: "We will locate a technician and confirm your request*.",
    icon: MapPinIcon,
  },
  {
    name: "3. Get it done",
    description:
      "Our  technician will come and get the job done while you continue to focus on your business.",
    icon: WrenchScrewdriverIcon,
  },
];

const services = [
  "Spray Booth Filters",
  "Booth Pressure Washing",
  "Spray Booth Filter Replacement",
  "Paint Booth Interior Vacuum",
  "Booth Maintenance",
  "Booth Wall Wrap/Coatings",
  "Maintenance Contracts",
  "Booth Floor Wrap",
  "Air Compressors",
  "Spray Gun Cabinets",
  "Air Lines",
  "Spray Gun Holders",
  "Air Line Moisture Control",
  "Air Line Track Systems",
  "Light Bulb Replacement",
  "Curtains",
  "Automatic Dust Control Systems",
  "Light Fixture Replacement",
  "Full Shop Vacuum Systems",
  "Exhaust Fan Replacement",
  "Booth Auto Balance Systems",
  "Booth Motor Replacement",
  "Booth Door Seal Replacement",
  "Pit Cleaning",
  "Booth & Shop Car Lifts",
  "Portable Car Lifts",
];

function App() {
  return (
    <div className="w-full">
      <header className="bg-green-50">
        <div className="relative pt-6 pb-16 sm:pb-24">
          <Popover>
            <nav
              className="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6"
              aria-label="Global"
            >
              <div className="flex flex-1 items-center">
                <div className="flex w-full items-center justify-between md:w-auto">
                  <a href="/">
                    <span className="sr-only">The Paint Booth Team</span>
                    <img
                      className="h-8 w-auto sm:h-10"
                      src={SmallLogo}
                      alt="The Paint Booth Team"
                    />
                  </a>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-river-800 p-2 text-river-400 hover:bg-river-700 focus:outline-none focus:ring-2 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="hidden space-x-10 md:ml-10 md:flex">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="font-medium text-river-600 hover:text-river-900"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              <div className="hidden md:flex">
                <a
                  href="tel:+18555438728"
                  className="inline-flex items-center rounded-full border border-transparent bg-green-700 px-4 py-2 text-base font-medium text-green-50 hover:bg-green-900"
                >
                  1 (855) 5 GET PBT
                </a>
              </div>
            </nav>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
              >
                <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src={SmallLogo}
                        alt="The Paint Booth Team"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-river-400 hover:bg-river-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-river-700 hover:bg-river-50 hover:text-river-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <a
                    href="tel:+18555438728"
                    className="block w-full bg-river-50 px-5 py-3 text-center font-medium text-indigo-600 hover:bg-river-100"
                  >
                    1 (855) 5 GET PBT
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>

        <div className="w-full max-w-7xl mx-auto">
          <div className="py-10">
            <h1 className="font-semibold text-4xl text-center text-river-600 lg:text-5xl">
              On demand service for your
            </h1>
            <h1 className="font-semibold text-4xl text-center text-river-600 lg:text-5xl">
              <span className="text-green-500">paint booth</span> and{" "}
              <span className="text-green-500">body shop</span>!
            </h1>
            <p className="mt-4 text-center font-medium text-river-700 text-sm md:text-base">
              Currently servicing the Greater Phoenix Area
            </p>

            <div className="px-4">
              <img
                src={Hero}
                className="mt-10 w-full h-32 md:h-48 lg:h-96"
                alt="Body Shop"
              />
            </div>
          </div>
        </div>
      </header>

      <div
        id="howitworks"
        className="relative bg-white py-16 sm:py-24 lg:py-20"
      >
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <p className="mt-2 text-3xl font-bold tracking-tight text-green-600 sm:text-4xl">
            How it works
          </p>
          <p className="mt-2 text-lg text-river-600">It's easy as 1, 2, 3</p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {works.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root rounded-lg bg-white shadow-xl px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-2xl bg-green-900 p-3 shadow-lg">
                          <feature.icon
                            className="h-12 w-12 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-river-600">
                        {feature.name}
                      </h3>
                      <p className="mt-4 text-base text-river-400">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-12 w-full flex items-center justify-center">
          <a
            href="tel:+18555438728"
            className="mx-auto inline-flex items-center px-6 py-3 border border-transparent text-xl font-medium rounded-full shadow-sm text-green-50 bg-green-800 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Call to book your appointment
          </a>
        </div>

        <p className="mt-8 text-xs text-river-300 text-center">
          *Additional parts and supplies may be extra and will need to be
          verified beforehand to ensure we have everything we need to complete
          the job.
        </p>
      </div>

      <div id="services" className="bg-green-50">
        <div className="mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24">
          <h2 className="text-3xl font-bold tracking-tight text-green-900">
            Our services from filter installation & air filtration to motor
            maintenance…..we do it all!
          </h2>
          <p className="mt-4 w-full text-lg text-river-600">
            The key to producing high-quality paint jobs in your spray booth and
            keep production rolling is to keep it clean. Paint Booth maintenance
            will not only result in better finishes but also will drastically
            reduce the amount of time spent buffing out imperfections in
            finishes as well. In additional to quality assurance, a
            well-maintained paint booth is safer for employees and the
            environment.
          </p>
          <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-2 lg:mt-6 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-2">
            {services.map((service) => (
              <div key={service}>
                <div className="mt-2">
                  <h3 className="text-lg font-medium text-river-900">
                    {service}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div id="contact" className="bg-white">
        <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-lg md:grid md:max-w-none md:grid-cols-2 md:gap-8">
            <div>
              <h2 className="text-2xl font-bold text-river-900 sm:text-3xl sm:tracking-tight">
                Reservations
              </h2>
              <div className="mt-3">
                <p className="text-lg text-river-500">
                  Do you need technical assistance with your booth. Call to
                  reserve a technician today!
                </p>
              </div>
              <div className="mt-9">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <PhoneIcon
                      className="h-6 w-6 text-river-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-base text-river-500">
                    <p>+1 (855) 543 8728</p>
                    <p className="mt-1">Mon-Fri 8am to 6pm MST</p>
                  </div>
                </div>
                {/* <div className="mt-6 flex">
                  <div className="flex-shrink-0">
                    <EnvelopeIcon
                      className="h-6 w-6 text-river-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-base text-river-500">
                    <p>support@example.com</p>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="mt-12 sm:mt-16 md:mt-0">
              <h2 className="text-2xl font-bold text-river-900 sm:text-3xl sm:tracking-tight">
                Sales
              </h2>
              <div className="mt-3">
                <p className="text-lg text-river-500">
                  Are you looking to order one of our premium products give our
                  sales staff a call today!
                </p>
              </div>
              <div className="mt-9">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <PhoneIcon
                      className="h-6 w-6 text-river-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-base text-river-500">
                    <p>+1 (855) 543 8728</p>
                    <p className="mt-1">Mon-Fri 8am to 6pm MST</p>
                  </div>
                </div>
                {/* <div className="mt-6 flex">
                  <div className="flex-shrink-0">
                    <EnvelopeIcon
                      className="h-6 w-6 text-river-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-base text-river-500">
                    <p>support@example.com</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-white">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 flex items-center justify-center lg:px-8">
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center text-base text-river-400">
              &copy; 2021 Paint Booth Team. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
